.container {
  &.small {
    width: 40px;
    height: 40px;
  }

  &.medium {
    width: 65px;
    height: 65px;
  }

  &.large {
    width: 120px;
    height: 120px;
  }

  .circle {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

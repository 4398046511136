@import './../../_Shared/colors';

.appBar {
  display: flex;
  justify-content: center;
  background-color: white !important;
  height: 60px;

  .toolbar {
    display: flex;
    align-items: center;
    height: 100%;

    .logoIcon {
      color: $blue_darker;
    }

    .title {
      font-weight: 600;
      text-decoration: none;
      color: $blue_darker;
      margin-left: 5px;
      font-size: 18px;
      width: 100%;
    }
  }
}

@import './../_Shared/colors';

.container {
  .content {
    display: flex;
    margin-top: 60px;
    width: 100%;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .main {
      width: 100%;
    }
  }
}

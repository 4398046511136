@import './../../_Shared/colors';

.container {
  display: flex;
  flex-direction: column;
  width: 80px;

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    border-right: none;
    overflow: auto;
    width: auto;
  }

  .person,
  .all {
    padding: 5px 20px;
    margin: 10px 0px;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      padding: 20px 5px;
      margin: 0px 10px;
    }

    &:hover {
      background-color: $grey_200;
      border-radius: 0 50px 50px 0;

      @media only screen and (max-width: 600px) {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 40%;
        border-bottom-left-radius: 40%;
      }
    }

    &.active {
      background: $light_blue;
      border-radius: 0 50px 50px 0;

      @media only screen and (max-width: 600px) {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 40%;
        border-bottom-left-radius: 40%;
      }
    }
  }

  .all {
    display: flex;
    justify-content: center;

    span {
      font-size: 28px;
      color: $blue_darker;
    }
  }
}

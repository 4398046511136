.search {
  position: relative;
  border-radius: 4px;
  background-color: #f1f3f4;
  margin-left: 0;
  width: 100%;

  @media (min-width: 600px) {
    width: auto;
    margin-left: 8px;
  }

  .icon {
    height: 100%;
    display: flex;
    padding: 0px 16px;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    color: #5f6368;
  }

  .input input {
    width: 100%;
    padding: 8px 8px 8px 0px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding-left: calc(1em + 32px);

    @media (min-width: 600px) {
      width: 12ch;

      &:focus {
        width: 20ch;
      }
    }
  }
}
